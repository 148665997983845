import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import * as Parse from 'parse';
import './index.css';

const {
    VITE_PARSE_SERVER_APPLICATION_ID: parseAppID,
    VITE_PARSE_SERVER_KEY: parseJSKey,
    VITE_PARSE_SERVER_URL: parseServerUrl,
    // @ts-ignore
} = import.meta.env;

if (!parseAppID || !parseJSKey || !parseServerUrl) {
    throw new Error(
        `Ordne is not correctly configured: parseAppId=${parseAppID}, parseJSKey=${parseJSKey}, parseServer=${parseServerUrl}`,
    );
}

Parse.initialize(parseAppID || '', parseJSKey);
Parse.User.allowCustomUserClass(true);
Parse.CoreManager.set('SERVER_URL', parseServerUrl);

// @ts-ignore
Parse.applicationId = parseAppID;
Parse.enableLocalDatastore();

window.Parse = Parse;

const App = lazy(() => import('./App.js'));

void import('./Sentry');

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
